import React from 'react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { BsFillPersonLinesFill } from 'react-icons/bs'

const SocialLinks = () => {

    const links = [
        {
            id: 1,
            child: (
                <>
                   LinkedIn <FaLinkedin size={30} />
                </>
            ),
            href: 'https://www.linkedin.com/in/monty-williams-304943172/',
            style: 'rounded-tr-md'
        },
        {
            id: 2,
            child: (
                <>
                   Github <FaGithub size={30} />
                </>
            ),
            href: 'https://github.com/MontyWilliams'
        },
        {
            id: 3,
            child: (
                <>
                   Mail <HiOutlineMail size={30} />
                </>
            ),
            href: 'mailto:MontyWilliams@918dcx.com',
            style: 'rounded-tr-md'
        },
        {
            id: 4,
            child: (
                <>
                   Resume <BsFillPersonLinesFill size={30} />
                </>
            ),
            href: '/Resume.pdf',
            style: 'rounded-br-md',
            download: true
        }
    ]

  return (
    <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
        <ul>
        {links.map((link) => (
            <li key={link.id} className={"flex justify-between items-center w-40 h-14 px-4 ml-[-100px] duration-[800ms]  bg-gradient-to-b from-black to-transparent hover:rounded-md hover:ml-[-10px]"
            + " " + link.style}>
                <a href={link.href} className="flex justify-between items-center w-full text-white"
                    download={link.download}
                    >
                    {link.child}
                </a>
            </li>

        ))}
        </ul>
    </div>
  )
}

export default SocialLinks
